import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"

import { Header } from "../components/Header"
import { Layout } from "../components/layout"
import { Container } from "../components/Container"
import { Section } from "../components/Section"

const CareerContainer = styled(Container)`
  max-width: 768px;
`

const Title = styled.h1`
  text-align: center;
`

const Frontmatter = styled.h4``

const Content = styled.div`
  margin-top: 48px;
`

export default function Template({ data }) {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark
  const page = data.allDataJson.edges[0].node.pages.careers
  const meta = {
    ...page.meta,
    url: page.meta.url.replace("/careers", frontmatter.path),
    title: `${frontmatter.title} | ${page.meta.title}`,
  }
  return (
    <Layout meta={meta} loadUikit={true}>
      <Header />
      <Section>
        <CareerContainer>
          <Title>{frontmatter.title}</Title>
          <Frontmatter>Availability: {frontmatter.availability}</Frontmatter>
          <Content dangerouslySetInnerHTML={{ __html: html }} />
        </CareerContainer>
      </Section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
        availability
      }
    }
    allDataJson {
      edges {
        node {
          pages {
            careers {
              meta {
                url
                title
                description
                imageRootUrl
                image
              }
            }
          }
        }
      }
    }
  }
`
